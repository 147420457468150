import { post, get, downFile } from 'common/request'

export default {
//根据ID查询
getById: id => get(`/scPropertyManagement/getById?id=${id}`),
//新增
add: args => post('/scPropertyManagement/add', args),
//分页查询
list: args => get('/scPropertyManagement/list', args),
//根据ID修改
update: args => post(`/scPropertyManagement/update`,args),
//根据ID删除
deleteById: id => post(`/scPropertyManagement/delete?id=${id}`),
}
